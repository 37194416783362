@import '~@pingux/end-user/lib/css/_global-vars.scss';

$popup-box-shadow: 0 3px 4px 0 rgba($color-black, 0.1);

div[data-id="app-container"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.u-pad-left-05 {
  margin-left: 0.5em;
}

/* monkey-patching below; remove after validating it works well without */
.tabs ul {
  &[data-id="tabs"] {
    padding: 0;
  }

  li.active:after {
    z-index: 1;
  }
}

.modal .modal-content:focus {
  box-shadow: none;
}

table {
  border-spacing: 0;
}

body {
  font-size: 14px;

  ::-ms-reveal {
    display: none;
  }
}

.button-group {
  text-align: center;
  font-size: 14px; // work-around UIP-3949
}

.pad-right {
  padding-right: 10px !important;
}
body .feedback {
  text-align: left;
}

.container {
  padding: 0;
}

body :focus {
  outline: none;
  box-shadow: none;
}

body {

  [tabindex],
  select,
  input,
  button,
  a,
  textarea {
    &:focus {
      box-shadow: 0 0 6px 0 $color-active-blue-highlighted;
    }
  }

  [tabindex="-1"]:focus {
    box-shadow: none;
  }
}

.button--as-link {
  color: #277ba5;
  font-size: 15px;
  cursor: pointer;
  height: 40px;
  width: auto;
  margin: 0 auto;
  border: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    box-shadow: none;
  }
}

body .page-messages {
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 100px;
  box-sizing: border-box;

  /* messages inside modal are shifted and looks not good, also height: 100% adds vertical scroll */
  &.in-modal {
    height: inherit;
    top: 0;
    padding-top: 0;
    box-sizing: content-box;
  }

  @media (max-width: 600px) {
    & .message--corner {
      max-width: none;
    }
  }
}

button.tile-button {
  flex-direction: row;
}

/* patching left side nav menu */
@media (max-width: 1119px) {
  .user-nav__collapsible-menu hr {
    width: 100%;
    margin: 30px 0;
  }

  .user-nav__info .user-info__image {
    margin: 0 auto 25px;
  }
}

.card {
  min-height: auto;
}

.account-table__row-subdetails {
  font-size: 90%;
}

.tile-button__icon-container,
button.tile-button__icon-container {
  flex-shrink: 0;
}

.device-selector__item-icon {
  .pingicon-clipboard {
    font-size: 20px;
  }
}

.serialized-data {
  font-size: 14px;
}

.serialized-data__value {
  font-weight: 400;
  font-size: 14px;
}

.fraud-reported {

  .icon-feedback--error,
  .icon-feedback__label {
    color: #ED3A03;
  }

  [data-id="report-id"] {
    color: #515960;
  }
}
@media (max-width: 1119px) {
.user-nav .navs{
  overflow-y: auto 
}
}